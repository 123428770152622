import React, { Fragment } from 'react';

import CardLink from 'components/CardLink/CardLink';
import Ellipse from './Ellipse.svg';
import { FormattedMessage } from 'react-intl';
import OrderStatus from 'components/OrderStatus';
import SVGIcon from '../SVGIcon/SVGIcon';
import { Skeleton } from 'antd';
import { StatusIcon } from './StatusIcon';
import StatusIcons from './StatusIcons';
import formatMoney from 'utils/formatMoney';
import media from 'utils/mediaStyle';
import messages from 'containers/OrdersPage/messages';
import moment from 'moment';
import styled from 'styled-components';

const OrderBody = ({ children }: { children: JSX.Element | JSX.Element[] }) => <div>{children}</div>;

interface CellProps {
  header: string | JSX.Element | JSX.Element[];
  content: string | JSX.Element | JSX.Element[];
  flex?: string;
}

const CellArrow = styled(SVGIcon)<any>`
  width: 26px;
  height: 26px;
`;

const CellLabel = styled.div`
  margin-bottom: 6pt;
  color: rgba(0, 0, 0, 0.54);
`;

const styleDiv = styled.div``;

const CellValue = styled(styleDiv)<any>`
  ${media.md`
    padding-right: 10px;
    white-space: nowrap;

    &.truncate {
      max-width: 15vw;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  `};
`;

const CellWrapper = styled.div<any>`
  flex: ${({ flex }) => flex || '1'};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  ${media.md`
    flex-direction: column;
  `};
`;

const Cell = ({ header, content, flex }: CellProps) => (
  <CellWrapper flex={flex}>
    <CellLabel>{header}</CellLabel>
    <CellValue className="no-wrap">{content}</CellValue>
  </CellWrapper>
);

const OrderRowWrapper = styled.div<any>`
  display: flex;
  flex-direction: column;
  ${media.md`
    flex-direction: row;
    align-items: center;
  `};
  background-color: #fcfffc;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 4px;
  margin-bottom: 5pt;
  padding: 6pt;
  cursor: pointer;

  :hover {
    color: rgba(0, 0, 0, 0.87);
    background-color: #f0f8fb;
    ${CellArrow} path {
      fill: #fff;
    }
    ${CellArrow} circle {
      fill: #2e904e;
    }
  }
  ${StatusIcon}:first-child {
    ${media.lg`
      margin-right: 14pt;
    `};
  }
`;

const OrderRow = ({ order = {} }: { order: any }) => (
  <CardLink hasShadow loading={order.loading} to={`/orders/${order.id}`}>
    <OrderRowWrapper status={order.status}>
      <Skeleton loading={order.loading} title={false} paragraph={{ rows: 2, width: '100%' }} active>
        {order.loading ? (
          false
        ) : (
          <>
            <div className="hideOnSmall">
              <StatusIcon status={order.status} src={StatusIcons[order.status]} />
            </div>
            <Cell
              header={<FormattedMessage {...messages.orderTime} />}
              content={`${moment(order.orderDate).format('HH:mm | DD/MM/YYYY')}`}
            />
            <Cell
              header={<FormattedMessage {...messages.deliveryTime} />}
              content={`${moment(order.deliveryTime.start).format('HH:mm')} - ${moment(order.deliveryTime.end).format(
                'HH:mm | DD/MM/YYYY',
              )}`}
            />
            <Cell header={<FormattedMessage {...messages.to} />} content={order.supplier.name} />
            <Cell header={<FormattedMessage {...messages.by} />} content={order.creator.name} />
            <Cell
              header={<FormattedMessage {...messages.status} />}
              content={<OrderStatus status={order.status} statusText={order.statusText} />}
            />
            <Cell header={<FormattedMessage {...messages.total} />} content={`${formatMoney(order.total)}đ`} />
            <div className="hideOnSmall">
              <CellArrow src={Ellipse} wrapper="span" className="hideOnMobile" />
            </div>
          </>
        )}
      </Skeleton>
    </OrderRowWrapper>
  </CardLink>
);

export default ({ orders }: { orders: any[] }) => {
  return (
    <Fragment>
      <OrderBody>
        {orders.map((order, index) => (
          <OrderRow key={index} order={order} />
        ))}
      </OrderBody>
    </Fragment>
  );
};

import PendingDelivery from './PendingDelivery.svg';
import Delivering from './Delivering.svg';
import Declined from './Declined.svg';
import Delivered from './Delivered.svg';
import Accepted from './Accepted.svg';
import InProgress from './InProgress.svg';
import PendingApproval from './PendingApproval.svg';
import PendingAcceptance from './PendingAcceptance.svg';
import Cancelled from './Cancelled.svg';
import ApprovalDisapproved from './ApprovalDisapproved.svg';
import Sent from './Sent.svg';

// This enum should be removed after integrating the schema generating from GraphQL
enum OrderStatus {
  ALL = 'ALL',
  APPROVAL_DISAPPROVED = 'APPROVAL_DISAPPROVED',
  PENDING_ACCEPTANCE = 'PENDING_ACCEPTANCE',
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  ACCEPTED = 'ACCEPTED',
  CANCELED = 'CANCELED',
  DECLINED = 'DECLINED',
  SENT = 'SENT',
  IN_PROGRESS = 'IN_PROGRESS',
  PENDING_DELIVERY = 'PENDING_DELIVERY',
  DELIVERING = 'DELIVERING',
  DELIVERED = 'DELIVERED',
}

const StatusIcons: { [key in OrderStatus]?: string | any } = {
  ACCEPTED: Accepted,
  SENT: Sent,
  PENDING_ACCEPTANCE: PendingAcceptance,
  PENDING_APPROVAL: PendingApproval,
  APPROVAL_DISAPPROVED: ApprovalDisapproved,
  CANCELED: Cancelled,
  DECLINED: Declined,
  DELIVERED: Delivered,
  PENDING_DELIVERY: PendingDelivery,
  DELIVERING: Delivering,
  IN_PROGRESS: InProgress,
};

export default StatusIcons;
